import React from 'react';
import { Input, Alert } from 'reactstrap';

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  autoComplete
}) => {
  return (
    <div style={{ marginBottom: '10px' }}>
      <label>{label}</label>
      <div>
        <Input
          {...input}
          placeholder={label}
          type={type}
          autoComplete={autoComplete}
        />
        {touched &&
          ((error && (
            <Alert style={{ marginTop: '5px' }} color="danger">
              {error}
            </Alert>
          )) ||
            (warning && <Alert className="alert">{warning}</Alert>))}
      </div>
    </div>
  );
};

export default renderField;
