import { BASE_URL, START_VERIFY, STOP_VERIFY, ORDER_ERROR } from "./types";
//TODO VERIFY_ERROR --> authcoden varmistus epäonnistui, näytä jotain
import axios from "axios";

export const createOrder = (basket, user, altShipping, postal) => async (dispatch) => {
  await localStorage.setItem("basket", JSON.stringify(basket));
  await localStorage.setItem("orderTime", JSON.stringify(Date.now()));

  //TRY
  try {
    let order;
    if (user.oneTime) {
      await localStorage.setItem("oneTimeUser", JSON.stringify(user));
      const order = await axios.post(`${BASE_URL}/v2/createOneTimeOrder`, {
        basket,
        user,
        altShipping,
        postal,
      });
      if (order.data.varasto && order.data.varasto.length) {
        dispatch({ type: ORDER_ERROR, payload: order.data.varasto });
      } else window.location.href = order.data.url;
    } else {
      axios.defaults.headers.common[`Authorization`] = user.token;
      const order = await axios.post(`${BASE_URL}/v2/createOrder`, {
        basket,
        user,
        altShipping,
        postal,
      });
      if (order.data.varasto) {
        dispatch({ type: ORDER_ERROR, payload: order.data.varasto });
      } else window.location.href = order.data.url;
    }
  } catch (e) {}
  //CATCH
};

export const verifyOrder = (data) => async (dispatch) => {
  dispatch({ type: START_VERIFY });
  try {
    if (data["checkout-account"]) {
      await axios.post(`${BASE_URL}/v2/verifyOrder`, { data });
    } else {
      await axios.post(`${BASE_URL}/verifyOrder`, { data });
    }
    dispatch({ type: STOP_VERIFY });
  } catch (e) {
    dispatch({ type: STOP_VERIFY });
  }
};
