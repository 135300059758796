import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';

export default function(ComposedComponent) {
  class Authentication extends Component {
    async componentWillMount() {
      let token = await localStorage.getItem('token');
      if (token && !this.props.authenticated) {
        await this.props.LoginWithToken(token);
      }
      if (!this.props.authenticated) {
        this.props.history.push('/kirjaudu');
      }
    }

    async componentWillUpdate(nextProps) {
      const token = await localStorage.getItem('token');
      if (token && this.props.authenticated) {
        this.props.history.push('/');
      }
      if (token && !this.props.authenticated) {
        await this.props.LoginWithToken(token);
      }
      if (!nextProps.authenticated) {
        this.props.history.push('/kirjaudu');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps({ user }) {
    return {
      authenticated: user.logged,
      user
    };
  }

  return connect(
    mapStateToProps,
    actions
  )(Authentication);
}
