import {
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  ADD_ITEMS,
  SUB_ITEMS,
  EMPTY_BASKET,
  RESTORE_BASKET,
  DELETE_BASKET,
  ADD_POSTAL,
  REMOVE_POSTAL,
  UPDATE_EXTRA_INFO_PRODUCTS,
  ORDER_ERROR
} from "../actions/types";

const INITIAL_STATE = {
  products: [],
  extraInfoProducts: "",
  errors: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_TO_BASKET: {
      let p = state.products;
      const index = state.products.findIndex(
        p =>
          p.main === action.payload.product.main &&
          p.sub === action.payload.product.sub &&
          p.product === action.payload.product.product &&
          p.details === action.payload.details.join(" - ")
      );

      if (index !== -1) {
        const initialAmount = parseInt(p[index].amount, 10);
        p[index].amount = parseInt(initialAmount + parseInt(action.payload.product.amountToAdd, 10), 10);
      } else {
        p.push({
          id: action.payload.product.id,
          main: action.payload.product.main,
          sub: action.payload.product.sub,
          product: action.payload.product.product,
          amount: action.payload.product.amountToAdd,
          price: action.payload.product.price,
          details: action.payload.details.join(" - ")
        });
      }
      return {
        ...state,
        products: p,
        errors: []
      };
    }
    case REMOVE_FROM_BASKET: {
      let p = state.products;
      p.splice(action.payload, 1); // Removes the first element of fruits
      return { ...state, products: p, errors: [] };
    }
    case ADD_ITEMS: {
      let p = state.products;
      const i = action.payload;
      let amount = state.products[i].amount;
      p[i].amount = amount + 1;
      return { ...state, products: p, errors: [] };
    }
    case SUB_ITEMS: {
      let p = state.products;
      const i = action.payload;
      let amount = state.products[i].amount;
      if (amount > 0) {
        p[i].amount = amount - 1;
      }
      return { ...state, products: p, errors: [] };
    }
    case EMPTY_BASKET:
      return { products: [], errors: [] };
    case DELETE_BASKET:
      return { products: [], errors: [] };
    case RESTORE_BASKET:
      return { products: action.payload.products, errors: [] };
    case ADD_POSTAL: {
      const current = state.products.filter(product => product.product !== "Toimituskulut");
      current.push({
        product: "Toimituskulut",
        amount: 1,
        price: action.payload
      });
      return { ...state, products: current, errors: [] };
    }
    case REMOVE_POSTAL: {
      const products = state.products;
      const p = products.filter(product => product.product !== "Toimituskulut");
      return { ...state, products: p, errors: [] };
    }
    case UPDATE_EXTRA_INFO_PRODUCTS:
      return { ...state, extraInfoProducts: action.payload, errors: [] };
    case ORDER_ERROR:
      return { ...state, errors: action.payload };
    default:
      return state;
  }
}
