import { combineReducers } from 'redux';
import categoriesReducer from './categories_reducer';
import basketReducer from './basket_reducer';
import userReducer from './user_reducer';
import generalReducer from './general_reducer';
import { reducer as form } from 'redux-form';

export default combineReducers({
  form,
  categories: categoriesReducer,
  basket: basketReducer,
  user: userReducer,
  general: generalReducer
});
