import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";
import { FaComments, FaShoppingBag, FaUser, FaSignOutAlt } from "react-icons/fa";
import { MdShoppingCart } from "react-icons/md";
import { connect } from "react-redux";
import { IMAGE_URL, BASE_URL } from "../actions/types";
import * as actions from "../actions";
import renderField from "./utils/renderField";
import { reset } from "redux-form";

class HeaderMain extends Component {
  state = {
    detailsMenu: "Aikuiset",
    isOpen: false,
    dropdownState1: false,
    dropdownState2: false,
    dropdownState3: false,
    dropdownState4: false,
    top: "70px",
    modal: false,
    messageError: "",
    messageLoading: false,
  };

  componentDidUpdate() {
    if (this.state === true) this.setState({ isOpen: false });
  }

  toggleMobile = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggle = () => {
    this.setState({
      dropdownState1: false,
      dropdownState2: false,
      dropdownState3: false,
      dropdownState4: false,
      isOpen: false,
    });
  };

  toggleMenu1 = () => {
    this.setState({
      dropdownState1: !this.state.dropdownState1,
      dropdownState2: false,
      dropdownState3: false,
      dropdownState4: false,
      detailsMenu: "Aikuiset",
    });
  };
  toggleMenu2 = () => {
    this.setState({
      dropdownState2: !this.state.dropdownState2,
      dropdownState1: false,
      dropdownState3: false,
      dropdownState4: false,
      detailsMenu: "Lapset",
    });
  };
  toggleMenu3 = () => {
    this.setState({
      dropdownState3: !this.state.dropdownState3,
      dropdownState2: false,
      dropdownState1: false,
      dropdownState4: false,
      detailsMenu: "Koti",
    });
  };
  toggleMenu4 = () => {
    this.setState({
      dropdownState4: !this.state.dropdownState4,
      dropdownState2: false,
      dropdownState1: false,
      dropdownState3: false,
      detailsMenu: "Muut",
    });
  };

  componentDidMount() {
    this.props.getCategoriesMenu();
    this.props.getMainCategoryDescriptions();
    this.props.fetchProperties();
  }

  logout = () => {
    this.props.logout();
  };

  renderDetails(menu) {
    if (this.props.categories.categories_menu.Aikuiset.categories) {
      const dataDetails = this.props.categories.categories_menu[this.state.detailsMenu];
      const desc = { __html: dataDetails.description };

      const n = dataDetails.categories.length;

      const cat = JSON.parse(JSON.stringify(dataDetails.categories));

      const col1 = cat.sort().slice(0, Math.ceil(n / 2));
      const col2 = cat.sort().slice(Math.ceil(n / 2), n);
      const menuColumn1 = col1.map((cat) => {
        const link = `/${this.state.detailsMenu}/${cat}`;
        return (
          <div key={cat}>
            <Link
              onClick={() => {
                this.toggle(menu);
              }}
              to={link}
            >
              {cat}
            </Link>
          </div>
        );
      });

      const menuColumn2 = col2.map((cat) => {
        const link = `/${this.state.detailsMenu}/${cat}`;
        return (
          <div key={cat}>
            <Link
              onClick={() => {
                this.toggle(menu);
              }}
              to={link}
            >
              {cat}
            </Link>
          </div>
        );
      });

      return (
        <div>
          <div>
            <Row
              onMouseLeave={() =>
                this.setState({
                  dropdownState1: false,
                  dropdownState2: false,
                  dropdownState3: false,
                  dropdownState4: false,
                })
              }
              className="detailsContent"
              style={{ top: this.state.top }}
            >
              <Col md="8" className="menuLarge">
                <Row>
                  <Col sm="12">
                    <h3>{dataDetails.title}</h3>
                    <div dangerouslySetInnerHTML={desc} />
                  </Col>
                  <Col md="6" className="menuLarge">
                    {menuColumn1}
                  </Col>
                  <Col md="6" className="menuLarge">
                    {menuColumn2}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="menuSmall">
            {dataDetails.categories.map((cat) => {
              const link = `/${this.state.detailsMenu}/${cat}`;
              return (
                <div key={cat}>
                  <DropdownItem tag={Link} onClick={() => this.toggle()} to={link}>
                    {cat}
                  </DropdownItem>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else return <DropdownItem />;
  }

  renderCheckout() {
    if (!this.props.basket.products.length) {
      return <td />;
    }
    return (
      <td>
        <Link to="/kassa" onClick={this.toggle}>
          <MdShoppingCart size="1.5em" color="green" />
          <br />
          <span style={{ fontSize: "0.7em" }}>KASSA</span>
        </Link>
      </td>
    );
  }

  renderAccount() {
    if (!this.props.user.logged) {
      return (
        <td className="headerIcon">
          <Link to="/kirjaudu" onClick={this.toggle}>
            <FaUser size="1.5em" color="green" style={{ paddingBottom: "3px" }} />
            <br />
            <span style={{ fontSize: "0.7em" }}>KIRJAUDU</span>
          </Link>
        </td>
      );
    } else if (this.props.user.logged && !this.props.user.oneTime) {
      return (
        <td className="headerIcon">
          <Link to="/tili" onClick={this.toggle}>
            <FaUser size="1.5em" color="green" style={{ paddingBottom: "3px" }} />
            <br />
            <span style={{ fontSize: "0.7em" }}>TILI</span>
          </Link>
        </td>
      );
    }
  }

  renderLogout() {
    if (this.props.user.logged && !this.props.user.oneTime) {
      return (
        <td>
          <Link
            to="/"
            onClick={() => {
              this.logout();
              this.toggle();
            }}
          >
            <FaSignOutAlt size="1.5em" color="green" style={{ paddingBottom: "3px" }} />
            <br />
            <span style={{ fontSize: "0.7em" }}>KIRJAUDU ULOS</span>
          </Link>
        </td>
      );
    } else {
      return <td />;
    }
  }

  renderBagItems() {
    const items = this.props.basket.products.length;
    if (!items) {
      return "";
    }
    return (
      <span
        style={{
          position: "relative",
          color: "white",
          backgroundColor: "red",
          borderRadius: "6px",
          paddingTop: "1px",
          fontSize: "9px",
          left: "-25px",
          padding: "0 3px",
          textDecoration: "bold",
        }}
      >
        {items}
      </span>
    );
  }

  renderMenu1 = () => {
    return this.renderDetails("dropdownState1");
  };
  renderMenu2 = () => {
    return this.renderDetails("dropdownState2");
  };
  renderMenu3 = () => {
    return this.renderDetails("dropdownState3");
  };
  renderMenu4 = () => {
    return this.renderDetails("dropdownState4");
  };

  handleFormSubmit = async ({ name, email, message }) => {
    const { reset } = this.props;
    try {
      this.setState({ messageLoading: true });
      await axios.post(`${BASE_URL}/message`, {
        name,
        email,
        message,
      });
      reset();
      toast.success("Viestin lähtys onnistui!");
      this.toggleModal();
      this.setState({ messageLoading: false });
    } catch (e) {
      this.setState({ messageError: "Tapahtui virhe, yritä uudelleen" });
    }
  };

  renderButtons = () => {
    if (!this.state.messageLoading)
      return (
        <div>
          <Button type="submit" color="primary">
            Lähetä viesti!
          </Button>{" "}
          <Button color="secondary" onClick={this.toggleModal}>
            Peru
          </Button>
        </div>
      );
  };

  renderModal = () => {
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className}>
        <form onSubmit={this.props.handleSubmit(this.handleFormSubmit)}>
          <ModalHeader toggle={this.toggle}>Jätä viesti</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={{ size: 12 }}>
                <Field
                  name="name"
                  component={renderField}
                  label="Nimesi"
                  type="input"
                  autoComplete="name"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={{ size: 12 }}>
                <Field
                  name="email"
                  component={renderField}
                  type="email"
                  label="Sähköpostiosoitteesi"
                  autoComplete="email"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={{ size: 12 }}>
                <Field name="message" component={renderField} type="textarea" label="Viestisi" />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>{this.renderButtons()}</ModalFooter>
        </form>
      </Modal>
    );
  };

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <Container fluid style={{ backgroundColor: "#f8f9fa" }}>
          {this.renderModal()}
          <div>
            <Navbar color="light" light expand="md">
              <NavbarBrand tag={Link} to="/">
                <img src={`${IMAGE_URL}/logo.png`} alt="" height="80" />
              </NavbarBrand>
              <NavbarToggler onClick={this.toggleMobile} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <UncontrolledDropdown nav inNavbar isOpen={this.state.dropdownState1}>
                    <DropdownToggle
                      nav
                      caret
                      style={{ color: "rgba(0, 0, 0, 1)" }}
                      to="/Aikuiset"
                      tag={Link}
                      onClick={this.toggleMenu1}
                    >
                      AIKUISET
                    </DropdownToggle>
                    <DropdownMenu right>{this.renderMenu1()}</DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar isOpen={this.state.dropdownState2}>
                    <DropdownToggle
                      nav
                      caret
                      style={{ color: "rgba(0, 0, 0, 1)" }}
                      to="/Lapset"
                      tag={Link}
                      onClick={this.toggleMenu2}
                    >
                      LAPSET
                    </DropdownToggle>
                    <DropdownMenu right>{this.renderMenu2()}</DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar isOpen={this.state.dropdownState3}>
                    <DropdownToggle
                      nav
                      caret
                      style={{ color: "rgba(0, 0, 0, 1)" }}
                      to="/Koti"
                      tag={Link}
                      onClick={this.toggleMenu3}
                    >
                      KOTI
                    </DropdownToggle>
                    <DropdownMenu right>{this.renderMenu3()}</DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar isOpen={this.state.dropdownState4}>
                    <DropdownToggle
                      nav
                      caret
                      style={{ color: "rgba(0, 0, 0, 1)" }}
                      to="/Muut"
                      tag={Link}
                      onClick={this.toggleMenu4}
                    >
                      MUUT
                    </DropdownToggle>
                    <DropdownMenu right>{this.renderMenu4()}</DropdownMenu>
                  </UncontrolledDropdown>
                  <NavItem style={{ textAlign: "center", marginRight: "10px" }}>
                    <span
                      style={{
                        position: "relative",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td className="headerIcon">
                              <Link to="/ostoskori" onClick={this.toggle}>
                                <FaShoppingBag
                                  size="1.5em"
                                  color="green"
                                  style={{ paddingBottom: "3px" }}
                                />
                                {this.renderBagItems()}
                                <br />
                                <span style={{ fontSize: "0.7em" }}>KORI</span>
                              </Link>
                            </td>
                            <td className="headerIcon headerMessage" onClick={this.toggleModal}>
                              <FaComments size="1.5em" color="green" style={{ paddingBottom: "3px" }} />
                              <br />
                              <span style={{ fontSize: "0.7em" }}>JÄTÄ VIESTI</span>
                            </td>
                            {this.renderAccount()}
                            {this.renderCheckout()}
                            {this.renderLogout()}
                          </tr>
                        </tbody>
                      </table>
                    </span>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps({ categories, basket, user }) {
  return { categories, basket, user };
}

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Anna nimesi";
  }

  if (!values.message) {
    errors.message = "Kirjoita viesti";
  }

  if (!values.email) {
    errors.email = "Anna sähköpostiosoite";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Tarkasta sähköpostiosoite";
  }

  return errors;
};

export default React.memo(
  reduxForm({
    form: "message",
    fields: ["email", "name", "message"],
    validate,
    reset,
  })(connect(mapStateToProps, actions)(HeaderMain))
);
