import {
  GET_MAIN_CAT_DESCRIPTIONS,
  GET_CAT_MENU,
  GET_CAT_DESCRIPTION,
  SET_PROPERTIES,
  GET_SUB_CATEGORIES
} from "../actions/types";

const INITIAL_STATE = {
  categories_menu: {
    Aikuiset: {},
    Lapset: {},
    Koti: {},
    Muut: {}
  },
  categories_descriptions: {
    Aikuiset: {},
    Lapset: {},
    Koti: {},
    Muut: {}
  },
  //SubCategory!!!
  category_description: {
    products: [],
    description: ""
  },
  properties: [],
  subCategories: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MAIN_CAT_DESCRIPTIONS:
      return {
        ...state,
        categories_descriptions: action.payload
      };
    case GET_CAT_MENU:
      return {
        ...state,
        categories_menu: action.payload
      };
    case GET_CAT_DESCRIPTION:
      return {
        ...state,
        category_description: {
          products: action.payload.products,
          description: action.payload.description
        }
      };
    case SET_PROPERTIES:
      return {
        ...state,
        properties: action.payload
      };
    case GET_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload
      };

    default:
      return state;
  }
}
