import React, { Component } from "react";
import headerTop from "./headerTop";
import HeaderMain from "./HeaderMain";
import { connect } from "react-redux";
import * as actions from "../actions";

class Header extends Component {
  async componentDidMount() {
    const orderTime = await localStorage.getItem("orderTime");
    await localStorage.removeItem("oneTimeUser");

    if (orderTime) {
      if (Date.now() - JSON.parse(orderTime) > 3600000) {
        await localStorage.removeItem("basket");
        this.props.deleteBasket();
        return;
      }
    }
    this.props.checkBasket();
    this.props.checkOneTimeUser();

    if (!this.props.user.logged) {
      const token = localStorage.getItem("token");
      if (token) {
        this.props.LoginWithToken(token);
      }
    }
  }
  render() {
    return (
      <div className="headerMain sticky-top" style={{ marginBottom: "10px" }}>
        {headerTop}
        <HeaderMain />
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(
  mapStateToProps,
  actions
)(Header);
