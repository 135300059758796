import { LOADING, START_VERIFY, STOP_VERIFY } from '../actions/types';

const INITIAL_STATE = {
  loading: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload.loading };
    case START_VERIFY:
      return { ...state, loading: true };
    case STOP_VERIFY:
      return { ...state, loading: false };
    default:
      return state;
  }
}
