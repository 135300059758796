//export const BASE_URL = "http://localhost:3050/api";
export const IMAGE_URL = "https://kuvat.napparavera.fi";
export const BASE_URL = "https://api.napparavera.fi/api";

export const GET_MAIN_CAT_DESCRIPTIONS = "get_main_cat_descriptions";
export const GET_CAT_MENU = "get_cat_menu";
export const GET_CAT_DESCRIPTION = "get_cat_description";
export const GET_SUB_CATEGORIES = "get_sub_categories";
export const ADD_TO_BASKET = "add_to_basket";
export const RESTORE_BASKET = "restore_basket";
export const REMOVE_FROM_BASKET = "remove_from_basket";
export const UPDATE_EXTRA_INFO_PRODUCTS = "update_extra_info_products";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const LOGIN_FAIL = "login_fail";
export const LOGIN_START = "login_start";
export const ADD_ITEMS = "add_items";
export const SUB_ITEMS = "sub_items";
export const LOADING = "loading";
export const EMPTY_BASKET = "empty_basket";
export const DELETE_BASKET = "delete_baset";
export const START_VERIFY = "start_verify";
export const STOP_VERIFY = "stop_verify";
export const VERIFY_ERROR = "verify_error";
export const ADD_POSTAL = "add_postal";
export const REMOVE_POSTAL = "remove_postal";
export const SET_PROPERTIES = "set_properties";

export const ERROR_SIGNUP = "error_signup";
export const LOGIN_ONE_TIME = "login_one_time";
export const RESET_CODE = "reset_code";
export const RESET_ERRORS = "reset_errors";
export const PASSWORD_CHANGED = "password_changed";
export const PASSWORD_ERROR = "password_error";
export const UPDATED_USER = "updated_user";

export const ORDER_ERROR = "order_error";
