import {
  LOGIN,
  LOGIN_FAIL,
  LOGIN_START,
  LOGOUT,
  ERROR_SIGNUP,
  LOGIN_ONE_TIME,
  RESET_CODE,
  RESET_ERRORS,
  PASSWORD_CHANGED,
  PASSWORD_ERROR,
  UPDATED_USER,
  LOADING
} from "../actions/types";
import { toast } from "react-toastify";

const INITIAL_STATE = {
  loading: false,
  logged: false,
  oneTime: false,
  city: null,
  country: null,
  email: null,
  extraInfo: null,
  fname: null,
  lname: null,
  phone: null,
  street: null,
  token: null,
  newsLetter: false,
  error: "",
  resetcodeSet: false,
  _id: ""
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT: {
      return INITIAL_STATE;
    }
    case LOGIN:
      return {
        ...state,
        logged: true,
        city: action.payload.city,
        country: action.payload.country,
        email: action.payload.email,
        extraInfo: action.payload.extraInfo,
        fname: action.payload.fname,
        lname: action.payload.lname,
        phone: action.payload.phone,
        street: action.payload.street,
        zip: action.payload.zip,
        token: action.payload.token,
        newsLetter: action.payload.newsLetter,
        error: "",
        errorSignup: "",
        resetcodeSet: false,
        _id: action.payload._id,
        loading: false
      };
    case LOGIN_ONE_TIME:
      return {
        ...state,
        logged: true,
        oneTime: true,
        city: action.payload.city,
        country: action.payload.country,
        email: action.payload.email,
        extraInfo: action.payload.extraInfo,
        fname: action.payload.fname,
        lname: action.payload.lname,
        phone: action.payload.phone,
        street: action.payload.street,
        zip: action.payload.zip,
        token: action.payload.token,
        newsLetter: action.payload.newsLetter,
        error: "",
        errorSignup: "",
        loading: false
      };
    case LOGIN_START:
      return { ...state, error: "", errorSignup: "" };
    case LOGIN_FAIL:
      return {
        ...state,
        logged: false,
        error: action.payload,
        loading: false
      };
    case ERROR_SIGNUP:
      return { ...state, errorSignup: action.payload, loading: false };
    case RESET_CODE:
      return { ...state, resetcodeSet: true, error: "", loading: false };
    case RESET_ERRORS:
      return { ...state, error: "", errorSignup: "", loading: false };
    case PASSWORD_CHANGED:
      toast.success("Salasana vaihdettu");
      return { ...state, loading: false };
    case PASSWORD_ERROR:
      toast.error("Tapahtui virhe");
      return { ...state };
    case UPDATED_USER:
      const {
        city,
        country,
        email,
        extraInfo,
        fname,
        lname,
        newsLetter,
        phone,
        street,
        zip
      } = action.payload;
      toast.success("Tiedot päivitetty");
      return {
        ...state,
        city,
        country,
        email,
        extraInfo,
        fname,
        lname,
        newsLetter,
        phone,
        street,
        zip
      };
    case LOADING:
      return { ...state, loading: true };

    default:
      return state;
  }
}
