import React, { Component } from "react";
import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
import requireAuth from "./components/utils/requireAuth";

import GoogleAnalytics from "react-ga";
GoogleAnalytics.initialize("UA-45049788-2");
//ReactGA.pageview(window.location.pathname + window.location.search);

const loading = () => <div>Loading...</div>;

const Landing = Loadable({
  loader: () => import("./components/Landing"),
  loading,
});
const CategoryMain = Loadable({
  loader: () => import("./components/CategoryMain"),
  loading,
});
const Subcategory = Loadable({
  loader: () => import("./components/Subcategory"),
  loading,
});
const Product = Loadable({
  loader: () => import("./components/Product"),
  loading,
});
const Basket = Loadable({
  loader: () => import("./components/Basket"),
  loading,
});
const Login = Loadable({
  loader: () => import("./components/Login"),
  loading,
});
const Checkout = Loadable({
  loader: () => import("./components/Checkout"),
  loading,
});
const Account = Loadable({
  loader: () => import("./components/Account"),
  loading,
});
const Shipping = Loadable({
  loader: () => import("./components/Shipping"),
  loading,
});
const OrderSuccess = Loadable({
  loader: () => import("./components/OrderSuccess"),
  loading,
});
const OrderError = Loadable({
  loader: () => import("./components/OrderError"),
  loading,
});
const ResetPassword = Loadable({
  loader: () => import("./components/ResetPassword"),
  loading,
});

const Toimitus = Loadable({
  loader: () => import("./components/Toimitus"),
  loading,
});
const Yksityisyys = Loadable({
  loader: () => import("./components/Yksityisyys"),
  loading,
});

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname + prevProps.location.search;
      const nextPage = this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

class Main extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={withTracker(Landing)} />
        <Route exact path="/:mainCat(Aikuiset|Lapset|Koti|Muut)" component={withTracker(CategoryMain)} />
        <Route
          exact
          path="/:mainCat(Aikuiset|Lapset|Koti|Muut)/:subCat"
          component={withTracker(Subcategory)}
        />
        <Route
          exact
          path="/:mainCat(Aikuiset|Lapset|Koti|Muut)/:subCat/:product"
          component={withTracker(Product)}
        />
        <Route exact path="/ostoskori" component={withTracker(Basket)} />
        <Route exact path="/kirjaudu" component={withTracker(Login)} />
        <Route exact path="/kassa" component={withTracker(Checkout)} />
        <Route exact path="/tili" component={requireAuth(withTracker(Account))} />
        <Route exact path="/toimitus" component={requireAuth(withTracker(Shipping))} />
        <Route exact path="/tilaus" component={withTracker(OrderSuccess)} />
        <Route exact path="/virhe" component={withTracker(OrderError)} />
        <Route exact path="/toimitusehdot" component={withTracker(Toimitus)} />
        <Route exact path="/yksityisyydensuoja" component={withTracker(Yksityisyys)} />

        <Route exact path="/salasana_unohtunut" component={withTracker(ResetPassword)} />

        <Route path="*" component={withTracker(Landing)} />
      </Switch>
    );
  }
}

export default React.memo(Main);
