import React from "react";
import { Container, Row, Col } from "reactstrap";

import { FaPhone, FaFacebookSquare, FaInstagram, FaEnvelope } from "react-icons/fa";

const headerTop = (
  <div className="fullWidth headerTop">
    <Container fluid>
      <Row>
        <Col>
          <span>
            <FaPhone /> <span className="smallText">040 591 0840</span> <FaEnvelope />{" "}
            <span className="smallText"> verkkokauppa@napparavera.fi</span>
          </span>
          <span
            style={{
              float: "right",
            }}
            className="headerTopRight"
          >
            <a
              href="https://www.facebook.com/Näppärä-Vera-327744178684/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaFacebookSquare />
            </a>{" "}
            <a href="https://www.instagram.com/nappara_vera/" rel="noopener noreferrer" target="_blank">
              <FaInstagram />
            </a>
          </span>
        </Col>
      </Row>
    </Container>
  </div>
);

export default headerTop;
