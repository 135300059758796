import {
  BASE_URL,
  LOGIN,
  LOADING,
  LOGIN_FAIL,
  LOGIN_START,
  LOGOUT,
  EMPTY_BASKET,
  ERROR_SIGNUP,
  LOGIN_ONE_TIME,
  RESET_CODE,
  RESET_ERRORS,
  PASSWORD_CHANGED,
  PASSWORD_ERROR,
  UPDATED_USER
} from "./types";
import axios from "axios";

export const logout = () => async dispatch => {
  await localStorage.removeItem("token");
  dispatch({ type: LOGOUT });
  dispatch({ type: EMPTY_BASKET });
};

export const LoginWithToken = token => async dispatch => {
  //dispatch({ type: LOADING, payload: true });
  axios.defaults.headers.common[`Authorization`] = token;
  try {
    const user = await axios.get(`${BASE_URL}/getuser`);
    dispatch({ type: LOADING, payload: false });
    dispatch({ type: LOGIN, payload: { ...user.data.user, token } });
  } catch (e) {
    dispatch({ type: LOADING, payload: false });
    await localStorage.removeItem("token");
  }
};

export const tryLogin = (
  email,
  password,
  history,
  redirect
) => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: LOGIN_START });

  try {
    const user = await axios.post(`${BASE_URL}/signin`, { email, password });
    if (user.data.user.token.length) {
      await localStorage.setItem("token", user.data.user.token);
      dispatch({ type: LOADING, payload: false });
      dispatch({ type: LOGIN, payload: user.data.user });
      if (history && redirect) {
        if (redirect === "back") {
          history.goBack();
        } else {
          history.push(`/${redirect}`);
        }
      }
    } else {
      dispatch({ type: LOADING, payload: false });
      dispatch({ type: LOGIN_FAIL, payload: "Tarkasta tunnus ja salasana" });
    }
  } catch (e) {
    dispatch({ type: LOADING, payload: false });
    dispatch({ type: LOGIN_FAIL, payload: "Tarkasta tunnus ja salasana" });
  }

  //  dispatch({ type: LOGIN, payload: product });
};

export const signUp = (form, history, redirect) => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  dispatch({ type: LOGIN_START });

  form["phone"] = form["phone"].replace(/\s/g, "");

  try {
    const user = await axios.post(`${BASE_URL}/signup`, { form });
    dispatch({ type: LOADING, payload: false });
    if (user.data.user.oneTime) {
      dispatch({ type: LOGIN_ONE_TIME, payload: user.data.user });
    } else {
      await localStorage.setItem("token", user.data.user.token);
      dispatch({ type: LOGIN, payload: user.data.user });
    }
    history.push(`/${redirect}`);
  } catch (e) {
    dispatch({ type: LOADING, payload: false });
    dispatch({ type: ERROR_SIGNUP, payload: e.response.data || "" });
  }
};

export const updateUser = (user, token) => async dispatch => {
  axios.defaults.headers.common[`Authorization`] = token;
  dispatch({ type: LOADING, payload: true });
  const data = user;

  data["phone"] = data["phone"].replace(/\s/g, "");

  try {
    const response = await axios.post(`${BASE_URL}/updateUser`, { data });
    dispatch({ type: LOADING, payload: false });
    if (response.data === "ok") {
      dispatch({ type: UPDATED_USER, payload: data });
    }
  } catch (e) {
    dispatch({ type: LOADING, payload: false });
    dispatch({ type: ERROR_SIGNUP, payload: e.response.data || "" });
  }
};

export const checkOneTimeUser = () => async dispatch => {
  const user = await localStorage.getItem("oneTimeUser");
  if (user) {
    dispatch({ type: LOGIN_ONE_TIME, payload: JSON.parse(user) });
  }
};

export const requestReset = email => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  await axios.post(`${BASE_URL}/reset`, { email });
  dispatch({ type: RESET_CODE });
};

export const tryLoginWithCode = (
  email,
  code,
  history,
  redirect
) => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  try {
    const user = await axios.post(`${BASE_URL}/signinwithcode`, {
      email,
      code
    });
    if (user.data.user.token.length) {
      await localStorage.setItem("token", user.data.user.token);
      dispatch({ type: LOADING, payload: false });
      dispatch({ type: LOGIN, payload: user.data.user });
      if (history && redirect) {
        if (redirect === "back") {
          history.goBack();
        } else {
          history.push({
            pathname: `/${redirect}`,
            state: { loginWithCode: true }
          });
        }
      }
    } else {
      dispatch({ type: LOADING, payload: false });
      dispatch({ type: LOGIN_FAIL, payload: "Tarkasta koodi" });
    }
  } catch (e) {
    dispatch({ type: LOADING, payload: false });
    dispatch({ type: LOGIN_FAIL, payload: "Tarkasta koodi" });
  }
};

export const resetErrors = () => dispatch => {
  dispatch({ type: RESET_ERRORS });
};

export const changePassword = (password, token, history) => async dispatch => {
  dispatch({ type: LOADING, payload: true });
  axios.defaults.headers.common[`Authorization`] = token;
  try {
    await axios.post(`${BASE_URL}/changePassword`, { password });
    dispatch({ type: PASSWORD_CHANGED });
    history.push(`/`);
  } catch (e) {
    console.log(e);
    dispatch({ type: PASSWORD_ERROR, payload: false });
  }
};
