import {
  ADD_TO_BASKET,
  REMOVE_FROM_BASKET,
  ADD_ITEMS,
  SUB_ITEMS,
  RESTORE_BASKET,
  DELETE_BASKET,
  ADD_POSTAL,
  REMOVE_POSTAL,
  UPDATE_EXTRA_INFO_PRODUCTS
} from "./types";

export const addToBasket = (product, details) => async dispatch => {
  dispatch({ type: ADD_TO_BASKET, payload: { product, details } });
};

export const removeFromBasket = i => dispatch => {
  dispatch({ type: REMOVE_FROM_BASKET, payload: i });
};

export const addAmount = i => dispatch => {
  dispatch({ type: ADD_ITEMS, payload: i });
};

export const subAmount = i => dispatch => {
  dispatch({ type: SUB_ITEMS, payload: i });
};

export const checkBasket = () => async dispatch => {
  //await localStorage.removeItem('basket');
  let basket = await localStorage.getItem("basket");
  if (basket) {
    dispatch({ type: RESTORE_BASKET, payload: JSON.parse(basket) });
  }
};

export const deleteBasket = () => async dispatch => {
  let basket = await localStorage.getItem("basket");
  if (basket) {
    await localStorage.removeItem("basket");
  }
  dispatch({ type: DELETE_BASKET });
};

export const addPostal = postal => async dispatch => {
  dispatch({ type: ADD_POSTAL, payload: postal });
};

export const removePostal = () => async dispatch => {
  dispatch({ type: REMOVE_POSTAL });
};

export const updateExtraInfoProducts = extraInfo => dispatch => {
  dispatch({ type: UPDATE_EXTRA_INFO_PRODUCTS, payload: extraInfo });
};
