import React, { Component } from "react";
import "./App.css";

import Main from "./Main";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  render() {
    return (
      <div className="cont">
        <Header />
        <Main />
        <Footer />
      </div>
    );
  }
}

export default App;
