import {
  GET_MAIN_CAT_DESCRIPTIONS,
  GET_CAT_MENU,
  BASE_URL,
  GET_CAT_DESCRIPTION,
  SET_PROPERTIES,
  GET_SUB_CATEGORIES
} from "./types";
import axios from "axios";

export const getMainCategoryDescriptions = () => async dispatch => {
  const descriptions = await axios.get(
    `${BASE_URL}/getMainCategoryDescriptions`
  );
  dispatch({ type: GET_MAIN_CAT_DESCRIPTIONS, payload: descriptions.data });
};

export const getCategoriesMenu = () => async dispatch => {
  const descriptions = await axios.get(`${BASE_URL}/getCategoriesMenu`);
  dispatch({ type: GET_CAT_MENU, payload: descriptions.data });
  const subcats = await axios.get(`${BASE_URL}/getSubCategories`);
  dispatch({ type: GET_SUB_CATEGORIES, payload: subcats.data });
};

export const getCategory = cat => async dispatch => {
  const categ = await axios.post(`${BASE_URL}/getCategory`, {
    ParentCategory: cat.main,
    SubCategory: cat.sub
  });

  const products = await axios.post(`${BASE_URL}/getProductsOfCategory`, {
    ParentCategory: cat.main,
    SubCategory: cat.sub
  });

  dispatch({
    type: GET_CAT_DESCRIPTION,
    payload: { products: products.data, description: categ.data[0].description }
  });
};

export const fetchProperties = () => async dispatch => {
  const properties = await axios.get(`${BASE_URL}/fetchProperties`);
  dispatch({ type: SET_PROPERTIES, payload: properties.data });
};
