import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { FaPhone, FaEnvelope, FaFacebookSquare, FaInstagram } from "react-icons/fa";

class Footer extends Component {
  render() {
    return (
      <div className="footer ">
        <Container>
          <Row>
            <Col
              sm="12"
              lg="6"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                padding: "10px 0",
              }}
            >
              <FaPhone /> <span>040 591 0840</span> <FaEnvelope />{" "}
              <span> verkkokauppa@napparavera.fi</span> <br />
              Näppärä Vera Ky, Tiakkavaarantie 1, 82815 Marjovaara
            </Col>
            <Col
              sm="12"
              lg="6"
              className=""
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                padding: "10px 0",
              }}
            >
              <span
                style={{
                  float: "right",
                  fontSize: "32px",
                }}
                className="headerTopRight"
              >
                <a
                  href="https://www.facebook.com/Näppärä-Vera-327744178684/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FaFacebookSquare />
                </a>{" "}
                <a
                  href="https://www.instagram.com/nappara_vera/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FaInstagram />
                </a>
              </span>
              <Link to="/toimitusehdot">TOIMITUSEHDOT</Link> <br />
              <Link to="/yksityisyydensuoja">YKSITYISYYDENSUOJA</Link>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Footer;
